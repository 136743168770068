<template>
  <section>
    <div class="content-header">
      <h2>Work-integrated learning</h2>
    </div>
    <div class="content-wrapper">
      <p>Work-integrated learning (WIL) is a form of curricular experiential education that formally integrates a student's academic studies with experiences within a workplace or practice setting. WIL is helpful for skill development and building professional networks.</p>
      <p>Co-operative Education and Work-Integrated Learning Canada (CEWIL Canada) is the lead organization for work-integrated learning in this country.</p>
      <p>Check out <a href="https://cewilcanada.ca/" target="_blank">CEWIL’s website</a> to learn about the different types of experiential and work-integrated learning opportunities available to students. Share with students all the WIL opportunities available to them.</p>
    </div>
  </section>
</template>

<script>
// import Accordion from '../components/Accordion.vue'
// import SidebarMenu from '../components/SidebarMenu.vue'
// import {
//   Hooper,
//   Slide,
//   Navigation as HooperNavigation,
//   Pagination as HooperPagination
// } from 'hooper'
// import 'hooper/dist/hooper.css'

export default {
  name: '06',
  components: {
    // SidebarMenu,
    // Accordion,
    // Hooper,
    // Slide,
    // HooperNavigation,
    // HooperPagination
  },
  data () {
    return {
      publicPath: process.env.BASE_URL
    }
  },

  mounted () {
  }
}
</script>

<style>
</style>
